<template>
  <div>
    <div class="container">
      <div class="app-header">
        <img :alt="$config.client.name" :src="logoPath"/>
      </div>
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
        no-wrap
      >
      </b-overlay>
      <div class="form">
        <h5 class="title">{{ $t('reinitPassword.title') }}</h5>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form>
            <div class="form-row">
              <ValidationProvider
                ref="password"
                :rules="{ required: true, regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/ }"
                v-slot="{ classes, errors }"
                vid="confirmation"
              >
                <div class="control" :class="classes">
                  <label
                    class="required"
                    for="reinit-password1"
                  >
                    {{ $t('reinitPassword.newLabel') }}
                  </label>
                  <div class="input-group flex-nowrap">
                    <input
                      v-model="password1"
                      id="reinit-password1"
                      data-test="reinit-password1"
                      class="form-control"
                      :type="showPassword ? 'text' : 'password'"
                      :placeholder="$t('words.password')"
                    >
                    <span
                      class="input-group-text"
                    >
                      <b-icon
                        :icon="showPassword?'eye-slash-fill':'eye-fill'"
                        data-test="reinit-toggleShowPassword"
                        :aria-label="$t('ariaLabels.displayPassword')"
                        @click="showPassword = !showPassword"
                      />
                    </span>
                  </div>
                  <span class="form-errors">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-row">
              <ValidationProvider ref="password" rules="required|confirmed:confirmation" v-slot="{ classes, errors }">
                <div class="control" :class="classes">
                  <label
                    class="required"
                    for="reinit-password2"
                  >
                    {{ $t('reinitPassword.confirmLabel') }}
                  </label>
                  <div class="input-group flex-nowrap">
                    <input
                      v-model="password2"
                      id="reinit-password2"
                      data-test="reinit-password2"
                      class="form-control"
                      :type="showPassword ? 'text' : 'password'"
                      :placeholder="$t('words.password')"
                    >
                    <span class="input-group-text">
                      <b-icon data-test="reinit-toggleShowPassword2"
                        :icon="showPassword?'eye-slash-fill':'eye-fill'"
                        :aria-label="$t('ariaLabels.displayPassword')"
                        @click="showPassword = !showPassword"
                      />
                    </span>
                  </div>
                  <span class="form-errors">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="infos">
              <ul>
                <li>{{ $t('reinitPassword.passwordHelp')[0] }}</li>
                <li>{{ $t('reinitPassword.passwordHelp')[1] }}</li>
                <li>{{ $t('reinitPassword.passwordHelp')[2] }}</li>
                <li>{{ $t('reinitPassword.passwordHelp')[3] }}</li>
              </ul>
            </div>
            <button
              type="button"
              class="btn btn-primary"
              data-test="reinit-submit"
              @click.prevent="handleSubmit(sendNewPassword)"
            >
              {{ $t('words.save') }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-test="reinit-signin"
              @click="$router.push({ name: 'SignIn' })"
            >
              {{ $t('words.cancel') }}
            </button>
          </form>
        </ValidationObserver>
      </div>
      <div class="messages">
        <div v-if="error">
          <p class="form-errors">{{ error }}</p>
        </div>
        <div v-if="success">
          <p class="form-success">{{ success }}</p>
        </div>
      </div>
    </div>
    <small class="footer">
      <p>{{ $t('footer') }} <a href="https://www.neogeo.fr/" target="_blank" rel="noopener">Neogeo-Technologies</a></p>
    </small>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import i18n from '@/i18n';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure,
} from 'vee-validate';

import { required, confirmed, regex } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: () => i18n.t('errors.required'),
});

extend('confirmed', {
  ...confirmed,
  message: () => i18n.t('errors.confirmPassword'),
});

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
});

extend('regex', {
  ...regex,
  message: i18n.t('errors.regexPassword'),
});

export default {
  name: 'ReinitPassword',

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data() {
    return {
      loading: false,
      password1: null,
      password2: null,
      showPassword: false
    }
  },

  computed: {
    ...mapState('forgotten-pwd', [
      'error',
      'success'
    ]),

    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    }
  },

  watch: {
    '$i18n.locale': function(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$refs.form.validate();
      }
    },
  },

  methods: {
    ...mapActions('forgotten-pwd', [
      'CONFIRM_NEW_PASSWORD'
    ]),

    sendNewPassword() {
      this.loading = true;
      this.CONFIRM_NEW_PASSWORD({
        token: this.$route.query.token,
        password: this.password1
      }).then(() => {
        this.loading = false;
      })
    }
  }
};
</script>

<style lang="less" scoped>

.container {
  margin: auto;
  width: 480px;
  height: fit-content;

  .form {

    .form-row {
      margin: 0 0 40px 0;
      display: block;
    }

    button.btn {
      float: right;
      position: relative;
      margin-left: 7px;
      margin-right: 0;
      margin-top: 1rem;
    }

    button.btn-primary {
      border: 2px solid #9BD0FF;
      border-radius: 8px;
    }
    button.btn-outline-secondary {
      background-color: #F7F8FA;
      border: 2px solid #A9B2B9;
      border-radius: 8px;
      color: #2F3234;
    }
    button.btn-outline-secondary:hover {
      color: white;
      background-color: #4b4b4b;
    }

  }
  .infos {
    font-size: 0.75em;
    text-align: justify;
    ul {
      padding-left: 1rem;
    }
  }
  .messages {
    display: inline-block;
    position: relative;
    top: 1.5em;
  }
}

.form-errors {
  color: #EB0600 !important;
  max-width: 40em;
}

.form-success {
  color: #11ac45 !important;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: small;
}

.footer a {
  text-decoration: none;
}

</style>
